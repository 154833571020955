#apexHelpContainer * {
  box-sizing: border-box;
}

.popup_wrapper {
  max-height: 100vh;
  overflow-y: auto;
}

.popup_wrapper .h1, .popup_wrapper .h2, .popup_wrapper .h3, .popup_wrapper .h4, .popup_wrapper .h5, .popup_wrapper .h6, .popup_wrapper h1, .popup_wrapper h2, .popup_wrapper h3, .popup_wrapper h4, .popup_wrapper h5, .popup_wrapper h6 {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.1;
  color: inherit;
}

#railContent {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #161616;
}

#apexHelpContainer .apex_content_area #railContent {
  margin: 0 10px 0 0 !important;
  position: relative;
  padding: 30px;
  overflow-y: auto !important;
  overflow-x: auto !important;
  width: auto !important;
  height: 600px;
  min-height: 60px;
  max-height: 600px;
}

#apexHelpContainer .dropdown-content>a#homeImage,
#apexHelpContainer .dropdown-content>a#newToBingAds,
#apexHelpContainer .dropdown-content>a#videoGallery,
#apexHelpContainer .dropdown-content>a  {
  display: block;
}

#apexHelpContainer.pubcenter_v4 .dropdown_moreoptions  a#newToBingAds,
#apexHelpContainer.pubcenter_v4 .dropdown_moreoptions  a#videoGallery {
  display: none;
}

#apex-suggestion-box .apex-topic {
  display: block !important;
  width: auto !important;
  height: auto !important;
  float: none !important;
}

#apex-suggestion-box .apex-topic:before {
  float: left !important;
}

#apex-suggestion-box ul>li a {
  padding: 0px 20px 0 30px !important;
}

#apexHelpContainer .apex_search_box {
  margin-top: 0px !important;
  display: flex;
}

#apexHelpContainer input.apex_querybutton {
  float: none !important;
  margin-left: 5px !important;
}

#apexHelpContainer .apex_search_area {
  margin-top: 0px !important;
  display: block !important;
  position: inherit !important;
  height: 60px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #d1d1d1;
  padding-top: 15px;
}

#apexHelpContainer .apex_header_area {
  border: none !important;
  margin: none !important;
  height: 31px !important;
  background-color: #323130 !important;
}

#apexHelpContainer, #apexHelpContainer .apex-help-container, #apexHelpContainer .apex_platform_chrome, #apexHelpContainer .apex_content_area {
  max-width: 90vw;
}

#apexHelpContainer input.apex_querybox {
  width: 100% !important;
  float: none !important;
}

#apexHelpContainer .apex_search_right {
  padding: 0px !important;
  max-width: 60vw;
}

#apexHelpContainer .apex_content_area #railContent {
  min-height: 70px !important;
}

#apexHelpContainer .apex_search_input_container {
  overflow: hidden;
  width: 100%
}